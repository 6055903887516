<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-8">Kullanıcılar</div>
            <div class="col-4 text-right">
              <CButton
                color="success"
                square
                size="sm"
                @click="openModalEvent('create','userForm', null, 'Yeni Kullanıcı Ekle')"
              >
                Kullanıcı Ekle
              </CButton>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>

          <CDataTable
            :items="users"
            :fields="fields"
            column-filter
            :items-per-page="10"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="openModalEvent('update', 'userForm', lastUser, lastUser.name + ' Düzenle')"
            v-on:refresh="getAllUsers"
            :loading="loading"
          >
            <template #activeFlag="{item}">
              <td>
                <CIcon
                  v-if="item.activeFlag > 0"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>

            <template #roleName="{item}">
              <td>
                <CBadge :color="colors[item.role.id % colors.length]" > {{ item.role.name }} </CBadge>
              </td>
            </template>

            <template #show_details="{item}">
              <td class="py-2">
                <CDropdown
                  toggler-text="İşlemler"
                  class="m-2"
                  color="info"
                  size="sm"
                >
                  <CDropdownItem @click="openModalEvent('update', 'userForm', item, item.name + ' Düzenle')">
                    Düzenle
                  </CDropdownItem>
                  <CDropdownItem @click="openModalEvent('changePassword', 'changePassword', item, item.emailAddress + ' Şifresini Değiştir')">
                    Şifre
                  </CDropdownItem>
                  <CDropdownItem @click="openModalEvent('destroy', 'confirm', item, item.name + ' isimli Kullanıcıyı Sil', item.name + ' - '+ item.emailAddress+' Kullanıcısını silmek üzeresiniz bunu yapmak istediğinize emin misiniz?')">
                    Sil
                  </CDropdownItem>
                </CDropdown>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>

    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="crudUser"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="crudUser"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
    />
  </CRow>
</template>

<script>
import FormModal from '../components/formModal.vue'
import ConfirmModal from '../components/confirmModal.vue'

export default {
  name: 'Users',
  components:{
    FormModal, ConfirmModal
  },
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'admin',
      form: 'userForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      colors: ['primary', 'success', 'warning', 'danger', 'info', 'dark', 'light'],
      lastUser: {},
      fields: [
        { key: 'show_details', label: 'İşlemler', filter: false},
        { key: 'id' },
        { key: 'emailAddress', label: 'Email' },
        { key: 'name', label: 'Ad' },
        { key: 'surname', label: 'Soyad' },
        { key: 'activeFlag', label: 'Aktif' },
        { key: 'roleName', label: 'Rol', filter: false },
      ],
      activePage: 1,
      filters: [
        {
          type: 'numeric',
          dataIndex: 'id'
        },
        {
          type: 'string',
          dataIndex: 'name'
        },
        {
          type: 'boolean',
          dataIndex: 'activeFlag'
        }
      ]
    }
  },
  computed:{
    users: function(){
      return this.$store.getters.allUsers
    },
    loggedUser: function(){
      return JSON.parse(localStorage.getItem("user")).loggedUser
    },
    loading: function(){
      return this.$store.getters.adminLoading
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
    modalProps: function(val){
      if(val && val.role)
        this.modalProps.roleId = val.role.id
      if(val && val.productionCompany)
        this.modalProps.productionCompanyId = val.productionCompany.id
    }
  },
  methods: {
    openModalEvent(actionType, form, data, title, desc){
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },
    rowClicked(item) {
      this.lastUser = item
    },
    getAllUsers() {
      this.$store.dispatch('allUser_list')
    },
    getAllRoles() {
      this.$store.dispatch('allRole_list')
    },
    getProductionCompanies() {
      this.$store.dispatch('productionCompanies_list')

    },
    async crudUser(item, actionType) {
      if(actionType == 'changePassword'){
        let data = {
          userId: item.id,
          newPassword: item.newPassword
        } 
        await this.$store.dispatch('userPassword_change', data)
      }else{
        item.activeFlag = item.activeFlag && item.activeFlag
        item.id = item.id ? item.id : ''
        item.numLoginFailures = item.numLoginFailures ? item.numLoginFailures : 0
        item.actionType = actionType
        item.productionCompanyId = item.roleId == 10 ? item.productionCompanyId : 0;

        await this.$store.dispatch('user_action', item)
      }
     
      if(this.$store.getters.userStatus.success) {
        this.getAllUsers();
        this.openModalEvent()
      }
     
    },

  },
  created() {
    this.getAllUsers()
    this.getAllRoles()
    this.getProductionCompanies();

  }
}
</script>
